import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClientService } from '../util-services/http-client.service';
import { ExivoComponentDbModel, StoreDbModel } from '../db-models/store-db.model';
import { LocalStorageService } from '../util-services/local-storage.service';
import { CryptoService } from '../util-services/crypto.service';
import { LOCAL_STORAGE_CONSTANT } from '../constants/localstorage.constant';
import { switchMap } from 'rxjs/operators';
import { WorkerService } from './worker.service';
import { HelperService } from '../util-services/helper.service';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    private httpClientService: HttpClientService,
    private localstorageService: LocalStorageService,
    private cryptoService: CryptoService,
    private workerService: WorkerService,
    private helperService: HelperService,
  ) {
  }

  all(): Observable<StoreDbModel[]> {
    let stores: StoreDbModel[] = this.getStoresFromLocalStorage();
    if (stores?.length > 0) {
      // Added uuid fix to give support for existing version localstroage cache
      if (stores[0]?.uuid === undefined) {
        stores = this.helperService.sortBy(stores, 'name');
        return of(stores);
      }
    }

    return this.httpClientService.get(`stores`, {}).pipe(
      (switchMap((result: StoreDbModel[]) => {
        if (result && result?.length > 0) {
          result = this.helperService.sortBy(result, 'name');
          this.setStoresInLocalStorage(result);
        } else {
          result = [];
          this.setStoresInLocalStorage(result);
        }
        return of(result);
      }))
    );
  }

  create(model: FormData): Observable<StoreDbModel> {
    return this.httpClientService.post('stores', model, {}).pipe(
      (switchMap((result: StoreDbModel) => {
        this.localstorageService.remove(LOCAL_STORAGE_CONSTANT.STORES_KEY);
        return of(result);
      }))
    );
  }

  edit(model: FormData, uuid: string): Observable<StoreDbModel> {
    return this.httpClientService.post(`stores/update/${uuid}`, model, {}).pipe(
      (switchMap((result: StoreDbModel) => {
        this.localstorageService.remove(LOCAL_STORAGE_CONSTANT.STORES_KEY);
        this.workerService.clearWorkersFromLocalStorage();
        return of(result);
      }))
    );
  }

  delete(uuid: string, migratedStoreUuid?: string): Observable<{ success: boolean }> {
    const params: Params = {};
    migratedStoreUuid && (params.migrate_store_uuid = migratedStoreUuid);
    return this.httpClientService.delete(`stores/${uuid}`, { params }).pipe(
      switchMap((result: { success: boolean }) => {
        if (result?.success) {
          this.localstorageService.remove(LOCAL_STORAGE_CONSTANT.STORES_KEY);
        }
        return of(result);
      })
    );
  }

  setStoresInLocalStorage(stores: StoreDbModel[]): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(stores)
    );
    this.localstorageService.set(LOCAL_STORAGE_CONSTANT.STORES_KEY, encryptedString);
  }

  getStoresFromLocalStorage(): StoreDbModel[] {
    const encryptedStores: string = this.localstorageService.get(LOCAL_STORAGE_CONSTANT.STORES_KEY);
    if (encryptedStores) {
      const decryptedStores: string = this.cryptoService.decryptValue(encryptedStores);
      const stores: StoreDbModel[] = JSON.parse(decryptedStores);
      return stores;
    } else {
      return null;
    }
  }

  multipleEdit(stores: StoreDbModel[]): Observable<{ success: boolean, stores: StoreDbModel[] }> {
    return this.httpClientService.post(`stores/multiple_edit`, stores, {}).pipe(
      (switchMap((result: { success: boolean, stores: StoreDbModel[] }) => {
        if (result?.stores?.length > 0) {
          this.setStoresInLocalStorage(result?.stores);
        }
        return of(result);
      }))
    );
  }

  getExivoComponents(uuid: string): Observable<ExivoComponentDbModel[]> {
    return this.httpClientService.get(`stores/get_exivo_components/${uuid}`, {});
  }

  getMappedEventStores(): Observable<StoreDbModel[]> {
    return this.httpClientService.get('stores/get_event_stores', {});
  }
}
