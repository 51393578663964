import { Routes } from '@angular/router';
import { SaferpayPaymentVerificationComponent } from '@app/saferpay-payment-verification/saferpay-payment-verification.component';
import { AuthGuard } from '@guard-services/auth.guard';
import { MfaGuard } from '@guard-services/mfa.guard';
import { OnboardGuard } from '@guard-services/onboard.guard';
import { BreadcrumbResolveFn } from '@resolves/breadcrumb.resolve';
import { WorkersResolveFn } from '@resolves/workers.resolve';
import { ForbiddenPageComponent } from '@static-pages/forbidden-page/forbidden-page.component';
import { PageNotFoundComponent } from '@static-pages/page-not-found/page-not-found.component';

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('@startsite/startsite.routes').then(m => m.startSiteRoute),
    canMatch: [AuthGuard, MfaGuard, OnboardGuard],
    pathMatch: 'full'
  },
  {
    path: 'partner-management',
    loadComponent: () => import('@partner-management-dashboard/partner-management-dashboard.component').then(m => m.PartnerManagementDashboardComponent),
    canMatch: [AuthGuard, MfaGuard, OnboardGuard],
    resolve: {
      breadcrumbs: BreadcrumbResolveFn,
      workers: WorkersResolveFn
    }
  },
  {
    path: 'auth',
    loadChildren: () => import('@auth/auth.routes').then(m => m.authRoutes),
    canMatch: [AuthGuard]
  },
  {
    path: 'onboard',
    loadChildren: () => import('@onboard/onboard.routes').then(m => m.OnboardModuleRoutes),
    canMatch: [AuthGuard, MfaGuard, OnboardGuard]
  },
  {
    path: 'app',
    loadChildren: () => import('@dashboard/dashboard.routes').then(m => m.dashboardRoutes),
    canMatch: [AuthGuard, MfaGuard, OnboardGuard],
    data: {
      breadcrumb: 'dashboard',
    }
  },
  {
    path: 'forbidden',
    component: ForbiddenPageComponent
  },
  {
    path: 'appointments',
    loadChildren: () => import('@appointments/appointments.routes').then(m => m.AppointmentsRoutes),
    data: {
      exclueJWT: true,
      hideResllerAdminBanner: true
    }
  },
  {
    path: 'events',
    loadChildren: () => import('@events/events.routes').then(m => m.EventsRoutes),
    data: {
      exclueJWT: true,
      hideResllerAdminBanner: true
    }
  },
  {
    path: 'settings/partners_settings/verify',
    loadComponent: () => import('@settings/partners-settings/partners-settings.component').then(m => m.PartnersSettingsComponent),
    data: {
      hideResllerAdminBanner: true
    }
  },
  {
    path: 'customers/:id',
    loadComponent: () => import('@customers-landing-page/customers-landing-page.component').then(m => m.CustomersLandingPageComponent),
    data: {
      exclueJWT: true,
      hideResllerAdminBanner: true
    }
  },
  {
    path: 'survey',
    loadChildren: () => import('@survey/survey.routes').then(m => m.surveyRoutes)
  },
  {
    path: 'logout',
    loadComponent: () => import('@logout/logout.component').then(m => m.LogoutComponent)
  },
  {
    path: 'connect',
    loadComponent: () => import('@connect/connect.component').then(m => m.ConnectComponent)
  },
  {
    path: 'saferpay/verify-payment',
    component: SaferpayPaymentVerificationComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];
