import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImportDynamicComponentService {

  importDealFormComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/deals-dashboard/deals-form/deals-form.component'
      ).then((m) => m.DealsFormComponent);
  }

  importCalioSettingFormComp(): () => Promise<any> {
    return () =>
      import(
        '@startsite/calio-settings-form/calio-settings-form.component'
      ).then((m) => m.CalioSettingsFormComponent);
  }

  importCustomerNotesFormComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/customer-notes-form/customer-notes-form.component'
      ).then((m) => m.CustomerNotesFormComponent);
  }

  importCreateSettingComponent(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/crm-dashboard/common/manage-partners-settings/create-form/create-form.component'
      ).then((m) => m.CreateFormComponent);
  }

  importQuestionFieldFormComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/question-fields/question-fields-form/question-fields-form.component'
      ).then((m) => m.QuestionFieldsFormComponent);
  }

  importCompanySettingTranslationFormComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/company-setting-translation/company-setting-translation-form/company-setting-translation-form.component'
      ).then((m) => m.CompanySettingTranslationFormComponent);
  }

  importStripeCardDialogComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/stripe-card-dialog/stripe-card-dialog.component'
      ).then((m) => m.StripeCardDialogComponent);
  }

  importResellerInviteComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/dashboard-common/reseller-feature/reseller-invite-dialog/reseller-invite-dialog.component'
      ).then((m) => m.ResellerInviteDialogComponent);
  }

  importServiceCategoryComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/appointment-forms/create-service-category/create-service-category.component'
      ).then((m) => m.CreateServiceCategoryComponent);
  }

  importAppointmentServiceComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/appointment-forms/appointment-services-form/appointment-services-form.component'
      ).then((m) => m.AppointmentServicesFormComponent);
  }

  importAppointmentServiceQuestionComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/appointment-forms/appointment-services-questions/appointment-services-questions.component'
      ).then((m) => m.AppointmentServicesQuestionsComponent);
  }

  importEmployeeFormComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/employee-form/employee-form-dialog/employee-form-dialog.component'
      ).then((m) => m.EmployeeFormDialogComponent);
  }

  importStoreFormComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/store-form/stores-form/stores-form.component'
      ).then((m) => m.StoresFormComponent);
  }

  importSmartWidgetStepFormComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/smart-widget-step-form/smart-widget-step-form.component'
      ).then((m) => m.SmartWidgetStepFormComponent);
  }

  importWidgetAppointmentGroupFormComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/widget-appointment-group/widget-appointment-group-form/widget-appointment-group-form.component'
      ).then((m) => m.WidgetAppointmentGroupFormComponent);
  }

  importCalendarSetupDialogComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/calendar-setup/calendar-setup.component'
      ).then((m) => m.CalendarSetupComponent);
  }

  importAvailabilitiesFormSwitcherComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/availability-forms/availabilities-form-switcher/availabilities-form-switcher.component'
      ).then((m) => m.AvailabilitiesFormSwitcherComponent);
  }

  importAbsensesFormComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/absences-forms/absenses-form/absenses-form.component'
      ).then((m) => m.AbsensesFormComponent);
  }

  importTemplateFormDialogComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/template-form-dialog/templates-form-dialog/templates-form-dialog.component'
      ).then((m) => m.TemplatesFormDialogComponent);
  }

  importCustomerTypesFormComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/customer-types-settings/customer-types-form/customer-types-form.component'
      ).then((m) => m.CustomerTypesFormComponent);
  }

  importCreateCalendarTypeComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/calendar-types-settings/create-calendar-type/create-calendar-type.component'
      ).then((m) => m.CreateCalendarTypeComponent);
  }

  importPartnerSettingModalComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/partner-setting-modal/partner-setting-modal.component'
      ).then((m) => m.PartnerSettingModalComponent);
  }

  importJsonViewerComp(): () => Promise<any> {
    return () =>
      import('@calio-dynamic-lib/json-viewer/json-viewer.component').then(
        (m) => m.JsonViewerComponent
      );
  }

  importJsonDiffViewerComp(): () => Promise<any> {
    return () =>
      import('@calio-dynamic-lib/json-diff-viewer/json-diff-viewer.component').then(
        (m) => m.JsonDiffViewerComponent
      );
  }

  importTaxesFormComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/taxes-settings/taxes-form/taxes-form.component'
      ).then((m) => m.TaxesFormComponent);
  }

  importConnectStripeStepperComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/payment-dashboard/connect-stripe-stepper/connect-stripe-stepper.component'
      ).then((m) => m.ConnectStripeStepperComponent);
  }

  importCalensoWidgetModalComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/calenso-widget-modal/calenso-widget-modal.component'
      ).then((m) => m.CalensoWidgetModalComponent);
  }

  importRebookAppointmentComp(): () => Promise<any> {
    return () =>
      import(
        '@appointments/rebook-appointment-dialog/rebook-appointment-dialog.component'
      ).then((m) => m.RebookAppointmentDialogComponent);
  }

  importConfirmationModalComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/confirmation-modal/confirmation-modal.component'
      ).then((m) => m.ConfirmationModalComponent);
  }

  importDeleteEventConfirmationModalComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/delete-event-confirmation-modal/delete-event-confirmation-modal.component'
      ).then((m) => m.DeleteEventConfirmationModalComponent);
  }

  importEventSlotParticipantComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/events-dashboard/common/event-slot-participant/event-slot-participant.component'
      ).then((m) => m.EventSlotParticipantComponent);
  }

  importEventSlotEditComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/events-dashboard/common/event-slot-edit-dialog/event-slot-edit-dialog.component'
      ).then((m) => m.EventSlotEditDialogComponent);
  }

  importSmartActionTriggerComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/smart-actions/smart-action-card/smart-action-trigger-dialog/smart-action-trigger-dialog.component'
      ).then((m) => m.SmartActionTriggerDialogComponent);
  }

  importImageUploadDialogComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/image-upload-dialog/image-upload-dialog.component'
      ).then((m) => m.ImageUploadDialogComponent);
  }

  importDeleteAccountConfirmationDialogComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/delete-account-confirmation-modal/delete-account-confirmation-modal.component'
      ).then((m) => m.DeleteAccountConfirmationModalComponent);
  }

  importCustomerBookingDeleteComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/crm-dashboard/common/customer-booking-delete-confirmation-dialog/customer-booking-delete-confirmation-dialog.component'
      ).then((m) => m.CustomerBookingDeleteConfirmationDialogComponent);
  }

  importEventCopyCodeComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/events-dashboard/common/event-copy-code-dialog/event-copy-code-dialog.component'
      ).then((m) => m.EventCopyCodeDialogComponent);
  }

  importEventAddSlotBottomSheetComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/events-dashboard/common/event-add-slot-bottomsheet/event-add-slot-bottomsheet.component'
      ).then((m) => m.EventAddSlotBottomsheetComponent);
  }

  importBryntumEventEditorComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/bryntum-event-forms/bryntum-event-editor/bryntum-event-editor.component'
      ).then((m) => m.BryntumEventEditorComponent);
  }

  importCalioPermissionModalComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/calio-permission-modal/calio-permission-modal.component'
      ).then((m) => m.CalioPermissionModalComponent);
  }

  viewPermissionModalComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/roles-permissions/pages/view-permissions/view-permissions.component'
      ).then((m) => m.ViewPermissionsComponent);
  }

  createRolesComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/roles-permissions/pages/create-roles/create-roles.component'
      ).then((m) => m.CreateRolesComponent);
  }

  createApiComp(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/manage-api-keys/pages/create-api-key/create-api-key.component'
      ).then((m) => m.CreateApiKeyComponent);
  }

  importMeetingSetupDialogComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/meeting-setup/meeting-setup.component'
      ).then((m) => m.MeetingSetupComponent);
  }

  importReleaseNotesDialogComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/release-notes/release-notes-settings-form/release-notes-settings-form.component'
      ).then((m) => m.ReleaseNotesSettingsFormComponent);
  }

  importPartnerReleaseNotesDialogComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/release-notes/partner-release-notes-list/partner-release-notes-list.component'
      ).then((m) => m.PartnerReleaseNotesListComponent);
  }

  importSmartWidgetFormDialogComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/smart-widget-form/smart-widget-form.component'
      ).then((m) => m.SmartWidgetFormComponent);
  }

  importQrCodeDialogComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/qr-code-dialog/qr-code-dialog.component'
      ).then((m) => m.QrCodeDialogComponent);
  }

  importSmartWidgetEmbedCodeDialogComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/smart-widget-embed-code-dialog/smart-widget-embed-code-dialog.component'
      ).then((m) => m.SmartWidgetEmbedCodeDialogComponent);
  }

  importTemplateRestrictionDialogComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/template-form-dialog/templates-restriction-form/templates-restriction-form.component'
      ).then((m) => m.TemplatesRestrictionFormComponent);
  }

  importBookingQuestionOrderingComp(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/booking-question-ordering/booking-question-ordering.component'
      ).then((m) => m.BookingQuestionOrderingComponent);
  }

  formFieldHistory(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/custom-form-field-history/custom-form-field-history.component'
      ).then((m) => m.CustomFormFieldHistoryComponent);
  }

  auditLogsExport(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/audit-logs/export-logs/export-logs.component'
      ).then((m) => m.ExportLogsComponent);
  }

  webhook(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/webhook/webhook.component'
      ).then((m) => m.WebhookComponent);
  }

  appointmentStatus(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/appointment-status-settings/appointment-status-add/appointment-status-add.component'
      ).then((m) => m.AppointmentStatusAddComponent);
  }

  updateAppointmentStatus(): () => Promise<any> {
    return () =>
      import(
        '@startsite/unconfirmed-appointments/update-appointment-status/update-appointment-status.component'
      ).then((m) => m.UpdateAppointmentStatusComponent);
  }

  showAppointmentStatusHistory(): () => Promise<any> {
    return () =>
      import(
        '@startsite/unconfirmed-appointments/appointment-status-history/appointment-status-history.component'
      ).then((m) => m.AppointmentStatusHistoryComponent);
  }

  youtubeVideoPreview(): () => Promise<any> {
    return () =>
      import('@modals/youtube-modal/youtube-modal.component').then((m) => m.YoutubeModalComponent);
  }

  sortMeetingProvider(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/meeting-dashboard/sort-meeting-provider/sort-meeting-provider.component'
      ).then((m) => m.SortMeetingProviderComponent);
  }

  manageSubscription(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/crm-dashboard/common/customer-admin-section/manage-subscription/manage-subscription.component'
      ).then((m) => m.ManageSubscriptionComponent);
  }

  cancelSubscription(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/your-subscription-settings/cancel-subscription/cancel-subscription.component'
      ).then((m) => m.CancelSubscriptionComponent);
  }

  manageBusinessHoursDialog(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/manage-booking-hours/manage-booking-hours.component'
      ).then((m) => m.ManageBookingHoursComponent);
  }

  emailSmsPreview(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/email-sms-view-settings/email-sms-preview/email-sms-preview.component'
      ).then((m) => m.EmailSmsPreviewComponent);
  }

  openHtmlPreview(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/email-sms-view-settings/email-preview/email-preview.component'
      ).then((m) => m.EmailPreviewComponent);
  }

  emailHistory(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/email-sms-view-settings/email-history/email-history.component'
      ).then((m) => m.EmailHistoryComponent);
  }

  resetWorkerMfa(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/resource-detail-settings/tabs/resource-security/reset-mfa/reset-mfa.component'
      ).then((m) => m.ResetMfaComponent);
  }

  activateMfa(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/resource-detail-settings/tabs/resource-security/activate-mfa/activate-mfa.component'
      ).then((m) => m.ActivateMfaComponent);
  }

  showMfaBackupCodes(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/resource-detail-settings/tabs/resource-security/show-backup-code/show-backup-code.component'
      ).then((m) => m.ShowBackupCodeComponent);
  }

  showGlobalNotification(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/global-notifications/create-notification/create-notification.component'
      ).then((m) => m.CreateNotificationComponent);
  }

  openCustomerModalForm(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/calio-create-customer-modal-form/calio-create-customer-modal-form.component'
      ).then((m) => m.CalioCreateCustomerModalFormComponent);
  }

  ImageModalComponent(): () => Promise<any> {
    return () =>
      import(
        '@modals/image-modal/image-modal.component'
      ).then((m) => m.ImageModalComponent);
  }

  calioCustomerBookingQuestions(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/landingpage-booking-questions-form/landingpage-booking-questions-form.component'
      ).then((m) => m.LandingpageBookingQuestionsFormComponent);
  }

  mappedServicesDialog(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/custom-field-mapped-services/custom-field-mapped-services.component'
      ).then((m) => m.CustomFieldMappedServicesComponent);
  }

  rebookGroupAppointmentDialog(): () => Promise<any> {
    return () =>
      import(
        '@events/rebook-group-appointment-dialog/rebook-group-appointment-dialog.component'
      ).then((m) => m.RebookGroupAppointmentDialogComponent);
  }

  deleteStoreConfirmationDialog(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/stores-settings/delete-dialog/delete-dialog.component'
      ).then((m) => m.DeleteDialogComponent);
  }

  thirdPartyIntegratinoApiErrorDialog(): () => Promise<any> {
    return () =>
      import(
        '@calio-dynamic-lib/third-party-api-error-details-dialog/third-party-api-error-details-dialog.component'
      ).then((m) => m.ApiErrorDetailsDialogComponent);
  }

  generateQrCodeComponent(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/conference-dashboard/generate-qr-code/generate-qr-code.component'
      ).then((m) => m.GenerateQrCodeComponent);
  }

  conferenceWorkersListDialog(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/conference-dashboard/workers-list-dialog/workers-list-dialog.component'
      ).then((m) => m.WorkersListDialogComponent);
  }

  supportUserLoginDialog(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/crm-dashboard/common/customer-admin-section/support-login-dialog/support-login-dialog.component'
      ).then((m) => m.SupportLoginDialogComponent);
  }

  createIntegrationConfigDialog(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/website-integration-dashboard/create-edit-configuration-dialog/create-edit-configuration-dialog.component'
      ).then((m) => m.CreateEditConfigurationDialogComponent);
  }

  customerLinksTableDialog(): () => Promise<any> {
    return () =>
      import('@dashboard/conference-dashboard/customer-links/customer-links.component').then((m) => m.CustomerLinksComponent);
  }

  mergeCustomerDialog(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/crm-dashboard/common/merge-customers/merge-customers.component'
      ).then((m) => m.MergeCustomersComponent);
  }

  openBookingTypesFormDialog(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/booking-types/create-booking-type/create-booking-type.component'
      ).then((m) => m.CreateBookingTypeComponent);
  }

  openBookingTypeAttributeFormDialog(): () => Promise<any> {
    return () =>
      import(
        '@dashboard/settings/pages/booking-type-attributes/create-booking-type-attribute/create-booking-type-attribute.component'
      ).then((m) => m.CreateBookingTypeAttributeComponent);
  }
}
