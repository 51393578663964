import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, RouteConfigLoadStart, Router, RouterOutlet } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { PartnerDbModel } from '@db-models/partner-db.model';
import { WorkerDbModel } from '@db-models/worker-db.model';
import { environment } from '@environments/environment';
import { AuthService } from '@feature-services/auth.service';
import { FeatureService } from '@feature-services/feature.service';
import { PartnerService } from '@feature-services/partner.service';
import { WorkerService } from '@feature-services/worker.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalioEventsService } from '@util-services/calio-events.service';
import { CalioRouterService } from '@util-services/calio-router.service';
import { LocalStorageService } from '@util-services/local-storage.service';
import { LoggerService } from '@util-services/logger.service';
import { NgProgressModule } from 'ngx-progressbar';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NgProgressModule, TranslateModule]
})
export class AppComponent implements OnInit {

  barLabel = 'Password strength:';
  options = {
    min: 0,
    max: 100,
    ease: 'easeInCubic',
    speed: 200,
    trickleSpeed: 300,
    meteor: true,
    spinner: true,
    color: '#68d6f0',
    thick: true
  };
  lang: string;
  isProd: boolean;
  googleTagManagerCode: string;
  isIeorEdgeBrowser = false;
  loggedInWorker: WorkerDbModel;
  partner: PartnerDbModel;
  hideResllerAdminBanner = false;
  resellerWhitelistPaths = [
    'appointments',
    'events',
    'customers',
    'settings'
  ];
  loggedIn = this.authService.hasAuthToken();

  constructor(
    public translate: TranslateService,
    public localStorageService: LocalStorageService,
    public partnerService: PartnerService,
    private router: Router,
    private calioEventsService: CalioEventsService,
    private workerService: WorkerService,
    private authService: AuthService,
    private ngZone: NgZone,
    public calioRouterService: CalioRouterService,
    private featureService: FeatureService,
    private msalAuthService: MsalService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof RouteConfigLoadStart)
    ).subscribe((event: RouteConfigLoadStart) => {
      if (
        (event.route.path && this.resellerWhitelistPaths.includes(event.route.path)) &&
        event.route.data?.hideResllerAdminBanner === true
      ) {
        this.hideResllerAdminBanner = true;
      }
    });

    this.isProd = environment.production;
    this.googleTagManagerCode = environment.googleTagManagerCode;
    this.partnerService.setLangInLocalStorage(null, null);
  }

  ngOnInit(): void {
    this.msalAuthService.handleRedirectObservable().subscribe();
    this.loggedInWorker = this.workerService.getLoggedInWorkerFromLocalStorage();
    this.partner = this.partnerService.getPartnerFromLocalStorage();

    this.authService.userIsLoggedInEvent.subscribe(worker => {
      this.loggedInWorker = worker;
      this.partner = this.partnerService.getPartnerFromLocalStorage();
      if (this.partner?.subscription) {
        this.featureService.loadSubscriptionFeatures(this.partner.subscription);
        this.featureService.loadFeaturesMasterList();
      }
      this.checkloadFreshDeskWidget();
    });

    this.authService.userLogOutEvent.subscribe(() => {
      this.loggedInWorker = undefined;
      this.partner = undefined;
    });

    if (this.loggedIn) {
      this.loggedInWorker = this.workerService.getLoggedInWorkerFromLocalStorage();
      this.featureService.initializeFeature();
      this.checkloadFreshDeskWidget();
    }

    this.router.events.pipe().subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.calioEventsService.hideHeaderEvent.emit(event.url.includes('/app/calendar'));
      }
    });
  }

  checkloadFreshDeskWidget() {
    // setTimeout is use to wait until loadSubscriptionFeatures set feature value
    setTimeout(() => {
      LoggerService.log('[is_freshdesk_enabled]', this.featureService.hasFeature('is_freshdesk_enabled'));
      if (this.featureService.hasFeature('is_freshdesk_enabled')) {
        this.loadFreshDeskWidget();
        // setTimeOut is use to wait until (Hilfe) is initialize
        setTimeout(() => {
          this.appendGaTrackingCode();
        }, 1000);
      }
    },);
  }

  private appendGaTrackingCode() {
    LoggerService.log('is_freshdesk_enabled => appendGaTrackingCode');
    try {
      const script = document.createElement('script');
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','` + this.googleTagManagerCode + `');`;
      document.head.appendChild(script);
    } catch (ex) {
      LoggerService.error('Error appending Google Tag Manager');
      LoggerService.error(ex);
    }
  }

  onFreshdeskLoad(): void {
    this.ngZone.run(() => {
      this.initializeFreshdeskAPI();
    });
  }

  initializeFreshdeskAPI(): void {
    LoggerService.log('Inside initializeFreshdeskAPI()');
    const script = document.createElement('script');
    script.innerHTML = `
        FreshworksWidget('prefill', 'ticketForm', {
        name: '${this.loggedInWorker ? this.loggedInWorker.resource_name : undefined} [${this.loggedInWorker ? this.loggedInWorker.partner_id : undefined}]',
        email: '${this.loggedInWorker ? this.loggedInWorker.email : undefined}',
        type: 'Question',
        custom_fields: {
          cf_order_id: '${this.loggedInWorker ? this.loggedInWorker.partner_id : undefined}'
        }
      });
    `;
    document.body.appendChild(script);
  }

  resetResellerAdmin(): void {
    this.workerService.resetResellerAdmin().subscribe({
      next: (result: { success: boolean }) => {
        result?.success && this.authService.logout();
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  private loadFreshDeskWidget(): void {
    if (
      window.location.href.indexOf(`${environment.deployUrl}/appointments`) > -1 ||
      window.location.href.indexOf(`${environment.deployUrl}/events`) > -1 ||
      window.location.href.indexOf(`${environment.deployUrl}/survey`) > -1 ||
      window.location.href.indexOf(`${environment.deployUrl}/app/calendar-pro`) > -1
    ) {
      LoggerService.log('Not loading freshdesk');
    } else {
      LoggerService.log('loading freshdesk');
      const script = document.createElement('script');
      script.innerHTML = `
        window.fwSettings = {
        'widget_id': 80000000638,
        'locale': 'de'
        };
        !function () {
          if ("function" != typeof window.FreshworksWidget) {
            var n = function () {
              n.q.push(arguments)
            };
            n.q = [], window.FreshworksWidget = n
          }
        }()
      `;

      const script1 = document.createElement('script');
      script1.type = 'text/javascript';
      script1.src = 'https://euc-widget.freshworks.com/widgets/80000000638.js';
      script1.async = true;
      script1.defer = true;
      script1.onload = this.onFreshdeskLoad.bind(this);
      document.body.appendChild(script);
      document.body.appendChild(script1);
    }
  }
}
