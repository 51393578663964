@if (!hideResllerAdminBanner && loggedInWorker?.base_account_partner_id != null && loggedInWorker?.base_account_partner_id !== loggedInWorker?.partner_id) {
  <div>
    <div class="alert alert-warning d-flex align-items-end justify-content-center" role="alert">
      {{ "general.reseller_warning_message" | translate: { reseller_admin: partner?.name } }}
      <button class="btn btn-primary btn-sm reset-reseller-btn ms-2" type="button" (click)="resetResellerAdmin()">
        <span aria-hidden="true">{{ 'header_component.logout' | translate }}</span>
      </button>
    </div>
  </div>
}

<ng-progress class="progress-bar progress-bar-success" id="myProgress" #progressBar [meteor]="options.meteor" [color]="options.color"
  [min]="options.min" [speed]="options.speed" [spinner]="options.spinner" [max]="options.max" [thick]="options.thick"
  [trickleSpeed]="options.trickleSpeed" [ease]="options.ease">
</ng-progress>
<router-outlet />
