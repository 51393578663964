export class StoreDbModel {
  id: number;
  uuid: string;
  name: string;
  email: string;
  phone: string;
  street: string;
  zip: number;
  city: string;
  lng: number;
  lat: number;
  is_online: number;
  place: string;
  position: number;
  is_multi_language: number;
  only_accessible_through_deeplink: number;
  country_id: number;
  logo: string;
  exivo_site_id?: string;
  exivo_component_uuids?: string[] | string;
  workers_count: number;
  appointment_lead_time?: number;
  _translations: {
    [key: string]: StoreTranslations;
  };
}

export class StoreTranslationForm {
  is_multi_language: boolean;
  formControl_default: StoreTranslations;
  id: number;
  uuid: string;
  street: string;
  zip: number;
  city: string;
  lng: number;
  lat: number;
  is_online: number;
  place: string;
  email: string;
  phone: string;
  country_id: number;
  logo: string;
  exivo_site_id?: string;
  exivo_component_uuids?: string[];
  appointment_lead_time: string;
  only_accessible_through_deeplink: number | boolean;
}

export class StoreTranslations {
  name: string;
  locale?: string;
}

export class ExivoComponentDbModel {
  id: string;
  uuid: string;
  identifier: string;
  labelling: string;
  accessZones: string[];
  templateIdentifier: string;
  ready: boolean;
  mode: string;
}
