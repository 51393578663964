export const CALIO_MAT_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const CALIO_MAT_YEAR_FORMAT = {
  parse: {
    dateInput: 'YYYY.MM.DD',
  },
  display: {
    dateInput: 'YYYY.MM.DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DATE_FORMATS = [
  'dd.MM.yyyy',
  'MM.dd.yyyy',
  'yyyy.MM.dd',
  'yyyy-MM-dd',
];

export const DATE_HYPEN_FORMATS = [
  'DD-MM-YYYY',
  'MM-DD-YYYY'
];

export const DATE_TIME_FORMAT = {
  MOMENT: 'DD.MM.yyyy HH:mm',
  DATE_PIPE: 'dd.MM.yyyy HH:mm',
  DATE_PIPE_WITH_COMMA: 'dd.MM.yyyy, HH:mm',
  DATE_PIPE_WITH_COMMA_AND_SECONDS: 'dd.MM.yyyy, HH:mm:SS'
};

export const DATE_ISO_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_ISO_T_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_TIME_ISO_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_ISO_ZERO_SECOND_FORMAT = 'YYYY-MM-DD HH:mm:00';
export const DATE_TIME_ISO_WITHOUT_SECOND_FORMAT = 'YYYY-MM-DD HH:mm';
export const ABSENSES_START_DATE_ISO_FORMAT = 'YYYY-MM-DD 00:00:00';
export const ABSENSES_END_DATE_ISO_FORMAT = 'YYYY-MM-DD 24:00:00';

export const DATE_FORMAT_WITH_DAY = 'EEE, dd.MM.yyyy'

export const DATE_FORMAT_DMY = {
  MOMENT: 'DD.MM.yyyy',
  DATE_PIPE: 'dd.MM.yyyy'
};

export const TIME_FORMAT = 'HH:mm';
export const TIME_ISO_FORMAT = 'HH:mm:ss';

export const DATE_PIPE_FORMAT_LANDINGPAGE = 'dd. MMMM yyyy';
export const DATE_TIME_PIPE_FORMAT_LANDINGPAGE = 'dd. MMMM yyyy HH:mm';
